export const addressPoints = [
  [50.8927369333, 4.4087452333, '899'],
  [50.8864473667, 4.3806136833, '631'],
  [50.9053927167, 4.42822265, '1130'],
  [50.9112774333, 4.4668027333, '17A'],
  [50.9146717667, 4.4773664833, '103'],
  [50.9123722, 4.4685087667, '32'],
  [50.9140814333, 4.4736708667, '72A'],
  [50.9133607167, 4.4730695833, '65'],
  [50.89849935, 4.4843253333, '8'],
  [50.8982524667, 4.4837739667, '5A'],
  [50.7983426667, 4.4060350833, '38'],
  [50.9003597167, 4.4772565, '20'],
  [50.90815565, 4.470603, '5'],
  [50.9192738333, 4.5435102833, '356'],
  [50.9182861833, 4.5408937167, '107'],
  [50.90028885, 4.3798328667, '697'],
  [50.9245097667, 4.41242555, '187'],
  [50.9032955667, 4.4821555167, '93B'],
  [50.9029325833, 4.4801074833, '79'],
  [50.8962754333, 4.4733100167, '17'],
  [50.8970069167, 4.4727237833, '1D'],
  [50.9270906333, 4.3962139333, '802'],
  [50.95356905, 4.3785904167, '419'],
  [50.94279195, 4.3830579333, '541'],
  [50.93764955, 4.3850314, '599'],
  [50.8984592, 4.4665057, '23'],
  [50.9001357167, 4.4679316667, '4'],
  [50.89871525, 4.4676048667, '14'],
  [50.8977558667, 4.4665362333, '31'],
  [50.8981989167, 4.4668991, '28'],
  [50.8960633167, 4.4614357, '67'],
  [50.8965228167, 4.4605625667, '42'],
  [50.89599285, 4.4605853833, '61A'],
  [50.8965562167, 4.4596334167, '53'],
  [50.8877845667, 4.4769104167, '15A'],
  [50.9030033, 4.4694327333, '8'],
  [50.7938986667, 4.3710440333, '77'],
  [50.8852888667, 4.46116795, '76'],
  [50.88152115, 4.46131485, '93'],
  [50.8856498167, 4.4611874667, '70'],
  [50.8824921167, 4.4614109333, '85'],
  [50.8907810833, 4.462108, '29'],
  [50.8822301, 4.4605952667, '112B'],
  [50.8189801, 4.3767644333, '19'],
  [50.8837635333, 4.4667121, '26'],
  [50.90506145, 4.4674941833, '7'],
  [50.9102952667, 4.4654520833, '64'],
  [50.8937888333, 4.4722797667, '12'],
  [50.8663474333, 4.4540325167, '182A'],
  [50.86872715, 4.4532476, '151'],
  [50.8305728333, 4.4437875667, '82'],
  [50.8052316167, 4.3947853833, '1'],
  [50.8061355333, 4.3955903667, '12'],
  [50.8063663, 4.3962931167, '13'],
  [50.8763691667, 4.51261455, '1/110'],
  [50.8139674667, 4.5456240667, '1146'],
  [50.8763409333, 4.51340955, '3/110'],
  [50.87878565, 4.5106821667, '3/88'],
  [50.8750760667, 4.4779026, '4'],
  [50.9116604333, 4.4715114333, '1'],
  [50.8069269167, 4.38494245, '522A'],
  [50.80130715, 4.37668905, '445'],
  [50.8009541833, 4.3783785, '455C'],
  [50.80395405, 4.3914879333, '580'],
  [50.8048039833, 4.3657911, '336'],
  [50.8040022167, 4.3941957, '604'],
  [50.80357305, 4.3683457167, '358A'],
  [50.8919046167, 4.4791651833, '30'],
  [50.8812478, 4.4878395, '20'],
  [50.8821842833, 4.48700355, '4'],
  [50.8808839, 4.4866251333, '17'],
  [50.8812351167, 4.4869114833, '13A'],
  [50.9388147833, 4.5583222333, '2/21'],
  [50.93286725, 4.5580919667, '19'],
  [50.9597843, 4.4837558833, '1/144'],
  [50.9662588833, 4.48466365, '229'],
  [50.8543168833, 4.5057733167, '200'],
  [50.8208649167, 4.3708165167, '72C'],
  [50.81611735, 4.3726213833, '124D'],
  [50.87786735, 4.4759980333, '3A'],
  [50.8750885833, 4.4785857167, '31'],
  [50.9064549667, 4.48231495, '4'],
  [50.90327315, 4.4842082833, '57'],
  [50.8499004667, 4.4102191667, '47'],
  [50.8674028167, 4.4119272333, '245B'],
  [50.8919335167, 4.45821125, '4C'],
  [50.8915381333, 4.46524225, '58'],
  [50.82507135, 4.36734685, '107A'],
  [50.8015971, 4.3955181667, '479'],
  [50.8248283, 4.3705193167, '126'],
  [50.8107359, 4.3894516833, '368'],
  [50.8060723667, 4.3939798167, '428'],
  [50.88530765, 4.49159645, '65'],
  [50.9056577833, 4.5300138, '24'],
  [50.8853397, 4.4748736833, '5'],
  [50.8813163167, 4.4745132667, '45'],
  [50.9004626167, 4.48148175, '5'],
  [50.9004783333, 4.4807893167, '8'],
  [50.9000177833, 4.4810738667, '2'],
  [50.8960816667, 4.4703379833, '22'],
  [50.89616535, 4.4704619, '26'],
  [50.8948282, 4.4632178833, '7'],
  [50.8950186, 4.4640242, '8C'],
  [50.8944589, 4.4631798333, '3'],
  [50.9216795, 4.5411416833, '21'],
  [50.8771747, 4.4758428833, '22'],
  [50.8764912333, 4.4760901, '18C'],
  [50.8605645333, 4.42419905, '229'],
  [50.8767564, 4.4218264667, '49'],
  [50.8764337167, 4.42402455, '55'],
  [50.8814851833, 4.4704820167, '9'],
  [50.8045451333, 4.3697163333, '25'],
  [50.8075005667, 4.3687474667, '60'],
  [50.8818073167, 4.4679643, '6'],
  [50.9070766, 4.4680242167, '14A'],
  [50.9097405333, 4.4787679167, '95'],
  [50.9086888833, 4.4752875667, '61'],
  [50.8891142333, 4.4571846167, '4'],
  [50.8886105167, 4.4627844833, '48A'],
  [50.8150867, 4.4041908167, '138'],
  [50.87318525, 4.5752061, '783'],
  [50.87086625, 4.5605686167, '3/668'],
  [50.8840771833, 4.5086316, '57'],
  [50.91535915, 4.4658985167, '34/2'],
  [50.9159540833, 4.4673968833, '20/2'],
  [50.8209210167, 4.3876718333, '90B'],
  [50.8263508333, 4.4048937333, '251'],
  [50.8233059333, 4.3950369833, '156'],
  [50.91330765, 4.47552755, '108'],
  [50.91156375, 4.4758556833, '90'],
  [50.915375, 4.4740628167, '130'],
  [50.9128134833, 4.4748703333, '107'],
  [50.9039296333, 4.4759913167, '1/48-5/48'],
  [50.9023766833, 4.47196845, '7'],
  [50.88444615, 4.4731180167, '1/143'],
  [50.8846051, 4.4703118167, '117'],
  [50.8842630167, 4.4702309167, '118'],
  [50.8847367167, 4.4610480333, '36'],
  [50.88480055, 4.4597682833, '24'],
  [50.8849434, 4.4633167, '55'],
  [50.8841223833, 4.4729087, '136'],
  [50.8846697, 4.4623996833, '44'],
  [50.8809580167, 4.4791391833, '34'],
  [50.8839041667, 4.4794138833, '2'],
  [50.8808555833, 4.47961625, '35'],
  [50.9161045333, 4.5591854333, '41'],
  [50.9127302333, 4.5677905167, '137'],
  [50.88142475, 4.4786348833, '164D'],
  [50.8832654167, 4.4652926667, '1/67'],
  [50.8829076, 4.46497065, '61'],
  [50.88299765, 4.4628680167, '45'],
  [50.8824531333, 4.47297485, '141'],
  [50.8824054833, 4.4672578333, '78'],
  [50.88210465, 4.4792012833, '191'],
  [50.8820588, 4.4799191833, '195'],
  [50.8820986333, 4.4731788833, '114'],
  [50.8833604167, 4.4567315167, '3'],
  [50.88277745, 4.4714596167, '123A'],
  [50.8818339, 4.4781130167, '158'],
  [50.88256465, 4.4739109667, '151A'],
  [50.88265405, 4.4627631667, '50A'],
  [50.8754141333, 4.46920485, '7'],
  [50.8820738333, 4.4926333167, '30'],
  [50.88069745, 4.4925708333, '42'],
  [50.8746732167, 4.4701863, '7'],
  [50.8749997667, 4.4700487, '12'],
  [50.8668511333, 4.4718110667, '166'],
  [50.8773583833, 4.4709902333, '33A'],
  [50.8916253, 4.47516515, '27'],
  [50.9015679, 4.4680723333, '16'],
  [50.9024751, 4.4690537, '34A'],
  [50.8831398167, 4.4779190333, '17'],
  [50.8811797, 4.4765765833, '34'],
  [50.8802896833, 4.4770558, '65'],
  [50.8875099833, 4.4575973667, '14'],
  [50.88784345, 4.4580772667, '19'],
  [50.8884945333, 4.45978905, '29D'],
  [50.8868802667, 4.4603641333, '36A'],
  [50.8875264167, 4.4573541, '8'],
  [50.90344895, 4.4673941667, '15'],
  [50.9016813667, 4.4703439167, '17'],
  [50.9047542667, 4.4700433333, '48/91'],
  [50.9041919167, 4.4691500833, '61/91'],
  [50.9042904667, 4.4691828667, '62/91'],
  [50.9062345, 4.4720598833, '134/91'],
  [50.9054577833, 4.4712800167, '124/91'],
  [50.90358525, 4.4846141, '12'],
  [50.89403175, 4.4658608667, '12'],
  [50.9272509333, 4.5707935167, '17'],
  [50.9135479833, 4.4783853833, '6'],
  [50.90758785, 4.4777916167, '41'],
  [50.8819775333, 4.4720601833, '45'],
  [50.8816046833, 4.4720110833, '51'],
  [50.8811635333, 4.4709188667, '54'],
  [50.8811236333, 4.4712705167, '56'],
  [50.8811993, 4.4719696333, '57'],
  [50.8808698167, 4.4712961833, '62B'],
  [50.8932706, 4.46028355, '3A'],
  [50.89367375, 4.4675524167, '60A'],
  [50.8935477333, 4.4677751333, '62'],
  [50.89047955, 4.3660982833, '48'],
  [50.9001283, 4.4864203833, '97'],
  [50.8987892, 4.4817609, '17'],
  [50.8996510167, 4.4832267667, '30'],
  [50.8755946667, 4.4783229167, '2'],
  [50.9126618333, 4.4735482833, '5'],
  [50.8835817667, 4.4818741, '16'],
  [50.8809023, 4.4816841, '50'],
  [50.8803979167, 4.4816530833, '56'],
  [50.8801843833, 4.48158535, '58'],
  [50.8861574833, 4.46575525, '2'],
  [50.8865969667, 4.4687519167, '29A'],
  [50.8865831, 4.4689422667, '31A'],
  [50.8863865333, 4.4673575, '11'],
  [50.88573305, 4.4662191833, '6'],
  [50.8860630667, 4.4741376333, '65'],
  [50.8856920333, 4.4742632667, '84'],
  [50.8863784667, 4.4716221167, '49A'],
  [50.92227225, 4.4756573333, '109'],
  [50.8948991667, 4.46678365, '16'],
  [50.8942335833, 4.4737639667, '98'],
  [50.8950013, 4.4717792, '75'],
  [50.8611102167, 4.4393358, '302'],
  [50.86279375, 4.4385341667, '281'],
  [50.87918835, 4.4355875333, '2/93'],
  [50.8672263, 4.4391822833, '234'],
  [50.86163445, 4.43881845, '295'],
  [50.89924075, 4.5129982667, '295'],
  [50.9111353167, 4.53571595, '542'],
  [50.9357644333, 4.45652615, '1/201'],
  [50.9359229, 4.4614506167, '157'],
  [50.8999206167, 4.4595150667, '3/3886'],
  [50.9508932667, 4.4345944, '3163'],
  [50.9459382167, 4.4300649167, '3215'],
  [50.9292768667, 4.4300710167, '3415'],
  [50.9095109, 4.45076725, '3718'],
  [50.9736479167, 4.4268099667, '2882'],
  [50.8828618833, 4.42668015, '1643'],
  [50.8875062667, 4.4321305667, '1713'],
  [50.8895077667, 4.4534430333, '1907'],
  [50.9307227667, 4.53812715, '1/618-10/618'],
  [50.9661303667, 4.5708020833, '2/1111'],
  [50.9667493333, 4.57131025, '1115'],
  [50.9214903, 4.5403513167, '347'],
  [50.9204983167, 4.5396219333, '225'],
  [50.9106344833, 4.4720128, '6'],
  [50.81422025, 4.5124331667, '36'],
  [50.8735445333, 4.4044031333, '276B'],
  [50.8684687167, 4.3787688, '66A'],
  [50.8875273, 4.4522017833, '24'],
  [50.8863276167, 4.4526999167, '39'],
  [50.8868312167, 4.45354015, '33B'],
  [50.8913701667, 4.4642759667, '30A-30D'],
  [50.8912003667, 4.46469755, '33'],
  [50.8898541333, 4.46475255, '49E'],
  [50.8879652167, 4.4644074, '61'],
  [50.8823897, 4.4862189833, '17'],
  [50.8809241333, 4.4860744, '32'],
  [50.8798386, 4.4844502667, '55B'],
  [50.8801634167, 4.37443835, '43'],
  [50.8124561333, 4.38300715, '6B'],
  [50.88385335, 4.4870479667, '9'],
  [50.8982804333, 4.46037375, '33'],
  [50.8980852, 4.46182235, '15'],
  [50.8977388167, 4.4616313167, '19'],
  [50.8991505333, 4.4622658, '5'],
  [50.8189469333, 4.4303026167, '312A'],
  [50.8039385667, 4.4012164167, '627C'],
  [50.8797264167, 4.4859489333, '12'],
  [50.91226175, 4.46593575, '11'],
  [50.8697908333, 4.4976855333, '2/143'],
  [50.8356297, 4.4656015333, '2/620'],
  [50.8662275, 4.4932509, '192'],
  [50.8733131, 4.4691004333, '3A'],
  [50.8739830167, 4.4704122667, '14'],
  [50.8924708, 4.4694829667, '3'],
  [50.8744096167, 4.47429405, '8'],
  [50.8820657333, 4.4624910833, '7'],
  [50.9006268333, 4.4836419, '1'],
  [50.9007548667, 4.4840674, '5'],
  [50.90087325, 4.4844819167, '9'],
  [50.8338485, 4.3905351667, '644'],
  [50.8342344833, 4.3914293833, '636'],
  [50.8445953833, 4.4381869167, '192'],
  [50.8371192833, 4.4187303167, '376'],
  [50.90479665, 4.4839646833, '3'],
  [50.9067455333, 4.4830679333, '25'],
  [50.9057146333, 4.4835634667, '15'],
  [50.9715911333, 4.5505416, '246'],
  [50.8757744167, 4.46972585, '1'],
  [50.9156256167, 4.4748749833, '78'],
  [50.9140692167, 4.4706085833, '46'],
  [50.9144000333, 4.4707812, '50'],
  [50.91552175, 4.47449895, '74'],
  [50.98098455, 4.4692836, '84'],
  [50.9207292667, 4.5435594333, '127'],
  [50.89574055, 4.4716702667, '6'],
  [50.8761172, 4.4749063833, '6A'],
  [50.9265146833, 4.4765383667, '109'],
  [50.9283181, 4.4771124167, '129'],
  [50.9239441667, 4.4794231667, '75'],
  [50.9206612167, 4.4765307833, '42'],
  [50.9817718333, 4.4597500333, '789'],
  [50.9483278667, 4.4699560333, '64'],
  [50.8977234667, 4.4828271167, '26'],
  [50.8968348, 4.4827666333, '27'],
  [50.8986448333, 4.4855899833, '56'],
  [50.896275, 4.48221365, '17'],
  [50.8841441667, 4.4660546, '3A'],
  [50.8839425167, 4.46582275, '7'],
  [50.8837516, 4.4660999833, '9'],
  [50.92482195, 4.4937379833, '85'],
  [50.9056018833, 4.3671315333, '89'],
  [50.9028616833, 4.4335225667, '29'],
  [50.91970595, 4.4043742667, '21'],
  [50.9090004, 4.472447, '108C'],
  [50.9118128833, 4.4706304, '134B'],
  [50.90227905, 4.4742904, '23'],
  [50.9025434333, 4.4745909167, '24'],
  [50.9029568833, 4.4739659833, '31'],
  [50.90506265, 4.4729767833, '49'],
  [50.9011779333, 4.47526505, '14'],
  [50.9010841833, 4.4755968, '8A'],
  [50.9114097833, 4.4693441333, '135A'],
  [50.9112668833, 4.4694056333, '135'],
  [50.9115359, 4.4690900833, '139C'],
  [50.9076818333, 4.47261165, '12/96'],
  [50.9078033833, 4.47231415, '3/96'],
  [50.8749301667, 4.4229940833, '269'],
  [50.8750526167, 4.4337470333, '173'],
  [50.8745754667, 4.4496806333, '32'],
  [50.8746651833, 4.43837135, '134'],
  [50.8819030667, 4.4655528667, '7'],
  [50.9012494167, 4.4836438333, '22A'],
  [50.8370500167, 4.4896684, '163'],
  [50.8274501333, 4.5007519833, '333'],
  [50.8190694, 4.5003232333, '8/369'],
  [50.8118820833, 4.50392555, '11/369'],
  [50.8356394, 4.4927849, '192'],
  [50.8393579167, 4.4857046667, '2/133'],
  [50.8598344333, 4.3976947833, '508'],
  [50.8599289833, 4.4315900833, '210'],
  [50.86096795, 4.45250335, '27'],
  [50.8599959167, 4.4481568167, '64'],
  [50.8603805, 4.4522459167, '31'],
  [50.9007490333, 4.4682728833, '14'],
  [50.9207404, 4.46524515, '58'],
  [50.9219467167, 4.4722808333, '30'],
  [50.9197512333, 4.4737709, '16'],
  [50.8956830333, 4.4710189333, '32'],
  [50.8841525333, 4.4636656, '175B'],
  [50.8942089833, 4.4696627, '54'],
  [50.89440395, 4.4690316833, '59'],
  [50.8913374667, 4.46691925, '83'],
  [50.89565655, 4.4702728333, '39'],
  [50.8974473167, 4.4733615667, '8'],
  [50.88740205, 4.4660429667, '108'],
  [50.8855956333, 4.46541875, '126'],
  [50.9080098833, 4.4672010667, '5'],
  [50.9086889667, 4.4694297167, '21'],
  [50.907833, 4.4666559167, '1'],
  [50.9122550333, 4.4799224833, '106'],
  [50.9016082667, 4.4806621, '30'],
  [50.89956085, 4.4797871333, '11'],
  [50.8992862833, 4.4802488833, '5'],
  [50.8993512667, 4.4806693, '1'],
  [50.9120037833, 4.47464375, '77A'],
  [50.9109971667, 4.4699570333, '42'],
  [50.9110468333, 4.46924275, '36B'],
  [50.91170015, 4.47359465, '63'],
  [50.8906532333, 4.4640676833, '26'],
  [50.89069985, 4.4635183333, '19'],
  [50.8239608833, 4.3939572, '117'],
  [50.9149649167, 4.475589, '9'],
  [50.883927, 4.46906515, '28A'],
  [50.8816625, 4.4696671833, '53A'],
  [50.88320755, 4.4696409167, '41'],
  [50.8857694833, 4.4693831333, '14'],
  [50.8853764833, 4.4693362333, '18'],
  [50.88522955, 4.4692991667, '20'],
  [50.8728499833, 4.46814045, '18B'],
  [50.87312285, 4.4687848, '12A'],
  [50.87312065, 4.4675707, '21'],
  [50.86081285, 4.4790711333, '33'],
  [50.8155769667, 4.44213865, '316'],
  [50.808759, 4.4183171167, '17'],
  [50.80809795, 4.4212756, '49'],
  [50.8198763333, 4.4462982, '382'],
  [50.9127876667, 4.4680122167, '13'],
  [50.9128397333, 4.4683664833, '9'],
  [50.9168508333, 4.4701398833, '211'],
  [50.91697015, 4.4853758167, '344'],
  [50.88274165, 4.4853534, '14'],
  [50.8142806667, 4.4076163167, '29'],
  [50.7908330333, 4.39227155, '154'],
  [50.8127630333, 4.3645959667, '38D'],
  [50.8806508333, 4.4700866667, '109'],
  [50.8810809667, 4.461937, '40'],
  [50.8811296167, 4.4604632667, '32'],
  [50.8811361, 4.4657563, '71'],
  [50.88035755, 4.4753576667, '147'],
  [50.8943151667, 4.4702028667, '7'],
  [50.892202, 4.4686328333, '16'],
  [50.89248485, 4.4687019167, '14'],
  [50.9064365333, 4.4541838333, '29'],
  [50.91901275, 4.46243355, '3'],
  [50.9016014833, 4.4860586167, '10'],
  [50.9113599, 4.467657, '52A'],
  [50.9076741, 4.46939185, '14'],
  [50.9078864833, 4.4699014333, '16'],
  [50.9084313667, 4.4690023, '28'],
  [50.9113586333, 4.4672288167, '51'],
  [50.8844905833, 4.4563941, '65'],
  [50.8863633167, 4.4571221667, '45B'],
  [50.889394, 4.4567865833, '13'],
  [50.8775351, 4.4826397667, '30A'],
  [50.8766636667, 4.482704, '31B'],
  [50.8773585333, 4.48356815, '38A'],
  [50.8761971167, 4.4787850833, '2'],
  [50.8997866833, 4.4810307833, '2'],
  [50.8867971833, 4.4705084167, '34'],
  [50.8841246, 4.4784325, '1/92'],
  [50.8852412667, 4.4950174167, '258'],
  [50.8877261667, 4.4675846667, '1/14-8/14'],
  [50.8853824333, 4.4904875333, '220'],
  [50.8871674667, 4.4675664, '16C'],
  [50.8865795333, 4.471651, '42A'],
  [50.8842659667, 4.4776982667, '80A'],
  [50.8845141833, 4.47672525, '78'],
  [50.8847382167, 4.4869962333, '188'],
  [50.8847101833, 4.4880854667, '196'],
  [50.88798625, 4.46700445, '6'],
  [50.8851925333, 4.4762547833, '72'],
  [50.8435605667, 4.3793269333, '20'],
  [50.8410302333, 4.3737431833, '77A'],
  [50.8409388, 4.3715485333, '965'],
  [50.86903025, 4.4050720333, '1410'],
  [50.8941101167, 4.46858455, '1B'],
  [50.9103484333, 4.57042275, '15'],
  [50.87485205, 4.4797409167, '8'],
  [50.8738682667, 4.4797568167, '3C'],
  [50.87624255, 4.4813098333, '22'],
  [50.9313707333, 4.4417409833, '106'],
  [50.9307743333, 4.46711525, '333'],
  [50.9140346667, 4.4774023333, '100'],
  [50.9060436, 4.48114975, '22'],
  [50.9102388667, 4.47919135, '50'],
  [50.9095526333, 4.47951535, '48'],
  [50.90547225, 4.47397175, '20B'],
  [50.9050343167, 4.47739, '24/37B'],
  [50.9048747833, 4.4744885333, '23'],
  [50.9069885833, 4.47905425, '58'],
  [50.87481615, 4.4682566167, '2'],
  [50.8759362, 4.4677101333, '11'],
  [50.87587545, 4.4680599833, '14'],
  [50.8130308833, 4.5297032667, '273'],
  [50.8070305833, 4.54991865, '483'],
  [50.8170449333, 4.5277756167, '1/232'],
  [50.8323760333, 4.5575159333, '1078'],
  [50.8929884833, 4.4571287, '11'],
  [50.8730790167, 4.4668945167, '14'],
  [50.8801277667, 4.3840239333, '459'],
  [50.8785101, 4.3802812167, '409'],
  [50.8773668333, 4.3733521333, '349'],
  [50.88208185, 4.4598317167, '9'],
  [50.8824702667, 4.4595934167, '4'],
  [50.90904455, 4.4673255, '8'],
  [50.9093457833, 4.46717355, '4'],
  [50.8484041333, 4.45409895, '372'],
  [50.8594542833, 4.4549678833, '5/246'],
  [50.81121545, 4.4523795667, '795'],
  [50.8452106, 4.4213902167, '154'],
  [50.8452146333, 4.4326596167, '54'],
  [50.8451894667, 4.4139933167, '218'],
  [50.8451457333, 4.4006193, '332'],
  [50.8455067667, 4.389923, '431'],
  [50.8452003167, 4.40904765, '262'],
  [50.9352309167, 4.4932222, '138'],
  [50.8547440667, 4.37743325, '31'],
  [50.8871386833, 4.4592945833, '51'],
  [50.8839426667, 4.4589834333, '89'],
  [50.8858030167, 4.4586246, '62'],
  [50.8924033, 4.4602587333, '9'],
  [50.8652348667, 4.3746078, '454'],
  [50.8675554, 4.3754905167, '424A'],
  [50.8979342, 4.4745448, '31A'],
  [50.8968049167, 4.47448785, '24A'],
  [50.8954172667, 4.4758408833, '2'],
  [50.8957927167, 4.4756119833, '8'],
  [50.8985112833, 4.4791536, '37'],
  [50.90029415, 4.4779031333, '57'],
  [50.9104050667, 4.4728724167, '249'],
  [50.9060373, 4.4757691167, '136'],
  [50.8999531167, 4.4780619, '55'],
  [50.913967, 4.4720198833, '294'],
  [50.9091188, 4.4743508333, '222A'],
  [50.8996625, 4.4783593833, '51'],
  [50.9088314833, 4.4744561333, '220'],
  [50.9140091667, 4.4714322, '295'],
  [50.9029671, 4.4766338333, '85'],
];
